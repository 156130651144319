import React, { Component } from "react";



export class Not_Found extends Component {
  render() {
    return (
      <>
        <div className="Not_found">PAGE NOT FOUND</div>
      </>
    );
  }
}

export default Not_Found;
